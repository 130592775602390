


















import { Component, Vue } from 'vue-property-decorator'
import isEmpty from 'lodash/isEmpty'

@Component
export default class AssociationsMenu extends Vue {
  statusTabs = [
    {
      label: '80% ou mais',
      query: {
        min: 80,
        max: 100
      }
    },
    {
      label: 'Entre 70% e 79%',
      query: {
        min: 70,
        max: 79
      }
    },
    {
      label: 'Entre 60% e 69%',
      query: {
        min: 60,
        max: 69
      }
    },
    {
      label: 'Solicitações',
      query: {
        min: 0,
        max: 101,
        requested: true
      }
    },
    {
      label: 'Todas',
      query: {
        min: 0,
        max: 101
      }
    },
    {
      label: 'Pendente Avalizar',
      query: {
        pending: true
      }
    }
  ]

  created () {
    if (isEmpty(this.$route.query)) {
      this.tabChange(0)
    }
  }

  selectedTab = {}

  tabChange (tab: number) {
    const route = this.statusTabs[tab]
    this.selectedTab = route

    const newRoute = {
      query: route.query
    }

    this.switchRoute(newRoute)
  }

  switchRoute (newRoute: Record<string, any>) {
    if (this.$route.name === 'AssociationIndex' && this.$router.resolve(newRoute).href !== this.$route.fullPath) {
      this.$router.push(newRoute)
    }
  }

  isTabSelected (route: any) {
    if (route.query.pending) {
      return Boolean(this.$route.query.pending)
    }

    return route.query.max.toString() === this.$route.query.max &&
          route.query.min.toString() === this.$route.query.min &&
          route.query.requested?.toString() === this.$route.query.requested
  }
}
